import { Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import closeMenuIcon from '../../../assets/images/close.svg'
import { styles } from './styles'

interface AndroidNoticeModalProps {
  open: boolean
  onClose: () => void
  t: TFunction
}

const AndroidNoticeModal = ({ open, onClose, t }: AndroidNoticeModalProps) => {
  const classes = styles()

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="chromeNoticeModal"
        className={classes.modal}
        classes={{
          paper: classes.maxHeight,
        }}
      >
        <div className={classes.modalContainer}>
          <button onClick={onClose} className={classes.closeButton}>
            <img src={closeMenuIcon} alt={t('imageAlts.close')} />
          </button>
          <Typography variant="h2" className={classes.modalTitle}>
            unsupported device
          </Typography>
          <Typography variant="subtitle1" align="center">
            chirpyest mobile extension is only available on IOS devices. but you
            can still benefit from cashback if you use our desktop extension.
          </Typography>
          <a href="#" className={classes.link} onClick={onClose}>
            continue to website
          </a>
        </div>
      </Dialog>
    </>
  )
}

export default withTranslation()(AndroidNoticeModal)
